import React from "react";
import { Layout } from "../components/Layout";
import { Projects } from "../components/Projects/Projects";
import { SEO } from "../components/SEO";

const ProjectsPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Projects - ACSS UPLB" />
      <Projects />
    </Layout>
  );
};

export default ProjectsPage;

import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { EventCardItem } from "../EventsAndActivities/EventCardItem";

import "./Projects.scss";

export const Projects: React.FC = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___title] }
        filter: { frontmatter: { type: { eq: "project" } } }
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              heroImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { edges } = allMarkdownRemark;
  return (
    <main id="projects">
      <h1 className="check-out-our">
        Check out our <span className="projects-name">PROJECTS</span>
      </h1>
      <section id="projects-grid-container">
        {edges.map((eventItem: any) => (
          <EventCardItem
            key={eventItem.node.id}
            link={eventItem.node.frontmatter.slug}
            title={eventItem.node.frontmatter.title}
            image={{
              alt: eventItem.node.frontmatter.title,
              src: eventItem.node.frontmatter.heroImage.childImageSharp.fluid,
            }}
          />
        ))}
      </section>
    </main>
  );
};
